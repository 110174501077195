import React from "react";
import deploy from "../../assets/images/deploy.svg";
import mobility from "../../assets/images/mobility.svg";
import bg from "../../assets/images/whats-new-bg.svg";
import immigration from "../../assets/icons/immigration.svg"
import leaves from "../../assets/icons/leave.svg"
import payroll from "../../assets/icons/payroll.svg"
import completedtask from "../../assets/icons/completed-task.svg"
import humanerror from "../../assets/icons/human-error.svg"
import onboarding from "../../assets/icons/onboarding.svg"
export default function EasyDeploy() {
  return (
    <>
      <div className="easy_deploy">
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-6 align-self-center">
              <h3>Easy to deploy, easier to learn</h3>
              <p>
                Felis vulputate est ut diam tincidunt. Malesuada posuere
                phasellus mollis amet, scelerisque id neque maecenas. Massa
                dolor a nullam odio semper. Nullam at feugiat arcu pellentesque
                quis semper.{" "}
              </p>
            </div>
            <div className="col-md-6">
              <img src={deploy} className="img-fluid" alt="" />
            </div>
          </div>
          <div className="row py-5">
            <div className="col-md-6">
              <img src={mobility} className="img-fluid" alt="" />
            </div>
            <div className="col-md-6 align-self-center">
              <h3>Designed for mobility and remote work.</h3>
              <p>
                Id diam laoreet adipiscing nunc enim. Quisque duis ut
                pellentesque enim nam ac. Neque venenatis velit pretium
                placerat. Risus eleifend odio elit vitae a magnis sodales sit.
                Et sed facilisis a, cursus turpis ornare sit. Tellus fames elit,
                leo at ullamcorper feugiat venenatis sapien a. Eu quisque et,
                nulla in orci eros. At arcu pellentesque mollis in.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="about_features"
        style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover" }}
      >
        <div className="container py-5">
          <div className="row">
            <div className="col-md-2">
              <div className="feature_box">
                <img
                  src={immigration}
                  className="img-fluid"
                  alt=""
                />
                <h6>Attendance</h6>
              </div>
            </div>
            <div className="col-md-2">
              <div className="feature_box">
                <img
                  src={leaves}
                  className="img-fluid"
                  alt=""
                />
                <h6>Leaves</h6>
              </div>
            </div>
            <div className="col-md-2">
              <div className="feature_box">
                <img
                  src={payroll}
                  className="img-fluid"
                  alt=""
                />
                <h6>Payroll</h6>
              </div>
            </div>
            <div className="col-md-2">
              <div className="feature_box">
                <img
                  src={completedtask}
                  className="img-fluid"
                  alt=""
                />
                <h6>Tasks</h6>
              </div>
            </div>
            <div className="col-md-2">
              <div className="feature_box">
                <img
                  src={humanerror}
                  className="img-fluid"
                  alt=""
                />
                <h6>Error & Request</h6>
              </div>
            </div>
            <div className="col-md-2">
              <div className="feature_box">
                <img
                  src={onboarding}
                  className="img-fluid"
                  alt=""
                />
                <h6>Onboarding</h6>
              </div>
            </div>
            <div className="col-md-8 text-center offset-md-2">
              <h2 className="text-white my-4 pt-3">
                Take advantage of the YAAMS Ecosystem
              </h2>
              <p className="text-white">
                Felis vulputate est ut diam tincidunt. Malesuada posuere
                phasellus mollis amet, scelerisque id neque maecenas. Massa
                dolor a nullam odio semper. Nullam at feugiat arcu pellentesque
                quis semper.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
