import React from "react";

import { Routes, Route } from "react-router-dom";
import "./style.css";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import ContactUs from "./components/pages/ContactUs";
import Header from "./components/headercomp/Header";
import Footer from "./components/footer/Footer";
import CoreHr from "./components/pages/CoreHr";
export default function App() {
  return (
    <>
    <Header/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/CoreHr" element={<CoreHr/>}/>
      </Routes>
      <Footer/>
    </>
  );
}
