import React from "react";
import CoreHr from "./tab-pages/CoreHr";

export default function LowerMiddleSection() {
  return (
    <>
      <div className="tabs_sec">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-12">
              <h2 class="big_heading text-center">
                <span className="position-relative">
                  New Age Features to build better workplaces
                </span>
              </h2>
              <ul className="nav nav-pills mb-3 mt-5" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-core-tab" data-bs-toggle="pill" data-bs-target="#pills-core" type="button" role="tab" aria-controls="pills-core" aria-selected="true">Core HR</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-payroll-tab" data-bs-toggle="pill" data-bs-target="#pills-payroll" type="button" role="tab" aria-controls="pills-payroll" aria-selected="false">Payroll</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-time-tab" data-bs-toggle="pill" data-bs-target="#pills-time" type="button" role="tab" aria-controls="pills-time" aria-selected="false">Time & Leaves</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-People-tab" data-bs-toggle="pill" data-bs-target="#pills-People" type="button" role="tab" aria-controls="pills-People" aria-selected="false">People Analytics</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-Onboarding-tab" data-bs-toggle="pill" data-bs-target="#pills-Onboarding" type="button" role="tab" aria-controls="pills-Onboarding" aria-selected="false">Onboarding</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-Task-tab" data-bs-toggle="pill" data-bs-target="#pills-Task" type="button" role="tab" aria-controls="pills-Task" aria-selected="false">Task & Checklist</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-Leave-tab" data-bs-toggle="pill" data-bs-target="#pills-Leave" type="button" role="tab" aria-controls="pills-Leave" aria-selected="false">Leave Management</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-Requests-tab" data-bs-toggle="pill" data-bs-target="#pills-Requests" type="button" role="tab" aria-controls="pills-Requests" aria-selected="false">Requests & Errors</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-Compensation-tab" data-bs-toggle="pill" data-bs-target="#pills-Compensation" type="button" role="tab" aria-controls="pills-Compensation" aria-selected="false">Compensation</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-self-tab" data-bs-toggle="pill" data-bs-target="#pills-self" type="button" role="tab" aria-controls="pills-self" aria-selected="false">Employee self-serive</button>
                  </li>
              </ul>
              <CoreHr/>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}
