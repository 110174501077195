import React from "react";
import corehr from "../../assets/images/core-hr.svg";
export default function InnerBanner() {
  return (
    <>
      <div className="inner_banner" style={{ backgroundColor: "#FEF3D1" }}>
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6">
              <h6 className="page_label">
                <span>Core HR</span>
              </h6>
              <h2 className="big_heading">Mind blowing global <br/>HR solutions </h2>

              <form className="banner-form mt-5">
                <div className="form-group position-relative">
                  <label>Start Free 30 Days Trial</label>
                  <input
                    type="email"
                    placeholder="Your Email"
                    className="form-control py-2"
                  />
                </div>
                <button className="btn btn-dark ms-2 py-2">Start Trial</button>
              </form>
            </div>
            <div className="col-md-6">
              <img src={corehr} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
