import React from "react";
import externalImage from "../../../assets/images/whats-new-bg.svg";
import qr from "../../../assets/icons/qr.svg";
import live from "../../../assets/icons/live.svg";
import home from "../../../assets/icons/home.svg";
import download from "../../../assets/icons/download.svg";
import library from "../../../assets/icons/library.svg";
import task from "../../../assets/icons/task.svg";
export default function WhatsNew() {
  return (
    <>
      <div
        className="what_new py-5"
        style={{ backgroundImage: `url(${externalImage})` }}
      >
        <div className="container">
          <div className="row gx-lg-5">
            <div className="col-md-12">
              <h2 className="big_heading text-white">
                <span className="position-relative">Whats’ New</span>
              </h2>
            </div>
            <div className="col-md-4">
              <div className="what_new_box rounded-5 shadow text-center bg-white p-5 mt-4">
                <img src={qr} alt="" />
                <h4 class="mt-4">Easy Attendance Management</h4>
                <p class="mb-0">
                  Employee attendance records at your fingertips with easy on
                  the go QR scan punch-in/ punch-out
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div className="what_new_box rounded-5 shadow text-center bg-white p-5 mt-4">
                <img src={live} alt="" />
                <h4 className="mt-4">Live Streaming</h4>
                <p className="mb-0">
                  Get real time updates & recent activities of team through live
                  streaming section{" "}
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div className="what_new_box rounded-5 shadow text-center bg-white p-5 mt-4">
                <img src={home} alt="" />
                <h4 className="mt-4">Work From Home Module</h4>
                <p className="mb-0">
                  Introducing the WFH mode for the new age hybrid work culture
                  accessible with same ease{" "}
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div className="what_new_box rounded-5 shadow text-center bg-white p-5 mt-4">
                <img src={download} alt="" />
                <h4 className="mt-4">Custom Reports</h4>
                <p className="mb-0">
                  From attendance to leaves record, download the customised
                  reports in different formats
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div className="what_new_box rounded-5 shadow text-center bg-white p-5 mt-4">
                <img src={library} alt="" />
                <h4 className="mt-4">Employee Library</h4>
                <p className="mb-0">
                  All the data ranging from reimbursement requests to total
                  clocked hours at once place for easy payroll calculation
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div className="what_new_box rounded-5 shadow text-center bg-white p-5 mt-4">
                <img src={task} alt="" />
                <h4 className="mt-4">Assign Task</h4>
                <p className="mb-0">
                  Introducing the WFH mode for the new age hybrid work culture
                  accessible with same ease{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
