import React from "react";
import features from "../../assets/images/features.svg";
import bg from "../../assets/images/features_bg.svg";
export default function JustFeatures() {
  return (
    <div className="just_features" style={{ backgroundImage: `url(${bg})` }}>
      <div className="container py-5">
        <div className="row gx-lg-5">
          <div className="col-md-12 mb-5">
            <h2 className="big_heading text-white">
              <span className="position-relative">Features just for you</span>
            </h2>
          </div>
          <div className="col-md-6">
            <div className="accordion" id="FeaturesAccordion">
              <div className="accordion-item">
                <h5 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Workforce Planning
                  </button>
                </h5>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#FeaturesAccordion"
                >
                  <div className="accordion-body">
                    Become future-ready with hybrid staffing models, extensive
                    manpower budgeting, annual operating plans, compensation
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h5 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Workforce Planning
                  </button>
                </h5>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#FeaturesAccordion"
                >
                  <div className="accordion-body">
                    Become future-ready with hybrid staffing models, extensive
                    manpower budgeting, annual operating plans, compensation
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h5 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Workforce Planning
                  </button>
                </h5>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#FeaturesAccordion"
                >
                  <div className="accordion-body">
                    Become future-ready with hybrid staffing models, extensive
                    manpower budgeting, annual operating plans, compensation{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <img src={features} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
