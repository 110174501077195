import React from "react";
import CorehrManagement from "../corehr/CorehrManagement";
import InnerBanner from "../corehr/InnerBanner";
import JustFeatures from "../corehr/JustFeatures";

import UpgradeSection from "../corehr/UpgradeSection";

export default function CoreHr() {
  return (
    <>
    <InnerBanner />
    <UpgradeSection/>
    <JustFeatures/>
    <CorehrManagement/>
    </>
  );
}
