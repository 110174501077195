import React from 'react'
import project from "../../assets/icons/project-management.svg"
import management from "../../assets/icons/management.svg"
import building from "../../assets/icons/building.svg"
export default function WhatWeDo() {
  return (
   <>
   <div className="what_we_do">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="big_heading">What We Do?</h2>
              <p>Et nulla hendrerit viverra consequat, morbi. Sit amet, dignissim at tincidunt fermentum, nec, odio placerat integer. Enim pulvinar sed viverra lectus vel consectetur odio lacus.</p>
         
            </div>
            <div className="col-md-4">
                <div className="what_we_box text-center border-end mt-4">
                    <img src={project} className="img-fluid"alt=''/>
                    <h5>Robust Attendance Management System</h5>
                </div>
            </div>
            <div className="col-md-4">
                <div className="what_we_box text-center border-end mt-4">
                    <img src={management} className="img-fluid"alt=''/>
                    <h5>Organizational level settings at your finger tips</h5>
                </div>
            </div>
            <div className="col-md-4">
                <div className="what_we_box text-center mt-4">
                    <img src={building} className="img-fluid" alt=''/>
                    <h5>Suitable for all kinds of industry standards</h5>
                </div>
            </div>
          </div>
        </div>
      </div>
   </>
  )
}
