import React from "react";

import WhatWeDo from "../aboutsection/WhatWeDo";
import VisionMisson from "../aboutsection/VisionMisson";
import EasyDeploy from "../aboutsection/EasyDeploy";
import Management from "../aboutsection/Management";
import AboutCompany from "../aboutsection/AboutCompany";

export default function About() {
  return (
    <>
      <AboutCompany />
      <WhatWeDo />
      <VisionMisson />
      <EasyDeploy />
      <Management />
    </>
  );
}
