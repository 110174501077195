import React from "react";
import { Link } from "react-router-dom";
import footerLogo from "../../assets/images/footer-bg.svg";
import footerBg from "../../assets/images/footer-bg.svg";
import {GoLocation} from "react-icons/go"
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { BiEnvelope } from "react-icons/bi";
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";
export default function Footer() {
  return (
    <>
      <footer
        className="text-white"
        style={{
          backgroundImage: `url(${footerBg})`,
          fontWeight: "300",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="row py-5">
            <div className="col-md-4">
              <img src={footerLogo} className="img-fluid footer_logo" alt="" />
              <p>
                High level experience in web design and development knowledge,
                producing quality work
              </p>
              <Link href="#" className="orange_white">
                Get Started
              </Link>
            </div>
            <div className="col-md-2 col-lg">
              <h4 className="mt-4">Product</h4>
              <ul className="footer_menu">
                <li>
                  <Link href="#">Landing Page</Link>
                </li>
                <li>
                  <Link href="#">Popup Builder</Link>
                </li>
                <li>
                  <Link href="#">Web-design</Link>
                </li>
                <li>
                  <Link href="#">Content</Link>
                </li>
                <li>
                  <Link href="#">Integrations</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-lg">
              <h4 className="mt-4">Resources</h4>
              <ul className="footer_menu">
                <li>
                  <Link href="#">Academy</Link>
                </li>
                <li>
                  <Link href="#">Blog</Link>
                </li>
                <li>
                  <Link href="#">Themes</Link>
                </li>
                <li>
                  <Link href="#">Hosting</Link>
                </li>
                <li>
                  <Link href="#">Developers</Link>
                </li>
                <li>
                  <Link href="#">Support</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-lg">
              <h4 className="mt-4">Contact</h4>
              <div className="contact_details">
                <Link href="mailto:info@yaams.net">
                  <BiEnvelope className="bi bi-envelope" /> info@yaams.net
                </Link>
                <Link href="tel:(270) 555-0117">
                  <BsFillTelephoneOutboundFill className="bi bi-telephone-plus" />{" "}
                  (270) 555-0117
                </Link>
                <Link
                  href="https://goo.gl/maps/T8LV4eBv7iK1P7659"
                  target="_blank"
                >
                  <GoLocation size={"40px"} /> Flaviant House 35 B Jacranda Marg DLF
                  Phase 2, Gurgaon - 122002 Haryana
                </Link>
              </div>
              <ul className="footer_social">
                <li>
                  <Link href="#">
                    <FaFacebook  />
                  </Link>
                </li>
                <li>
                  <Link href="#">
                    <FaInstagram  />
                  </Link>
                </li>
                <li>
                  <Link href="#">
                    <FaTwitter  />
                  </Link>
                </li>
                <li>
                  <Link href="#">
                    <FaLinkedin  />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="bottom_bar">
            <div>
              <Link href="#">Privacy Policy</Link>
              <Link href="#">Terms of Service</Link>
            </div>
            <div>©2022, All right reserved.</div>
          </div>
        </div>
      </footer>
    </>
  );
}
