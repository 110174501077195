import Banner from "../../assets/images/banner-mob.svg"

export default function UpperSection() {
  return (
    <div className="main_banner background_img">
    <div className="container py-5">
      <div className="row">
        <div className="col-md-6">
          <h1>
            Simplifying your day-to-day HR processes with <span>YAAMS</span>
          </h1>
          <h5 className="yaams">
            Yet Another Attendance Management System
          </h5>
          <form className="banner-form mt-5">
            <div className="form-group position-relative">
              <label>Start Free 30 Days Trial</label>
              <input
                type="email"
                placeholder="Enter Your Email"
                className="form-control py-2"
              />
            </div>
            <button class="btn btn-dark ms-2 py-2">Start Trial</button>
          </form>
        </div>
        {/* Column Closed */}
        <div className="col-md-6">
          <img src={Banner} alt="Banner_Image" className="img-fluid" />
        </div>
      </div>
    </div>
  </div>
  )
}
