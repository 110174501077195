import React from "react";
// Imports of Images
import App from "../../assets/images/app-s.svg";
import Play from "../../assets/images/app-s.svg";
import CheckOut from "./checkout/CheckOut";
import LowerMiddleSection from "./LowerMiddleSection";
import Manage from "./manage/Manage";
import WhatsNew from "./whatsnew/WhatsNew";
// Imports of Images Ends
export default function MiddleSection() {
  return (
    <>
      <div className="manage_org">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-12">
              <h2>
                <span className="position-relative">
                  Manage organization like never before
                </span>
              </h2>
              <h6>available on Android/ IOS</h6>
              <img src={App} alt="App" />
              <img src={Play} alt="App" />
            </div>
          </div>
        </div>
      </div>
      <LowerMiddleSection />
      <WhatsNew />
      <CheckOut />
      <Manage/>
    </>
  );
}
