import React from "react";

export default function LetsConnect() {
  const connectHandler = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <div className="lets_connect">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6">
              <div className="form_box">
                <h2 className="big_heading">Let’s Connect</h2>
                <p>Get your all doubts cleared at once !</p>
                <form method="post" onSubmit={connectHandler}>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="f_name"
                        placeholder="First Name"
                        className="form-control mb-3"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="l_name"
                        placeholder="Last Name"
                        className="form-control mb-3"
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="email"
                        name="email"
                        placeholder="Work Email"
                        className="form-control mb-3"
                      />
                      <input
                        type="tel"
                        name="phone"
                        placeholder="Phone Number"
                        className="form-control mb-3"
                      />
                      <input
                        type="number"
                        name="range"
                        placeholder="Employee Count range"
                        className="form-control mb-3"
                      />
                      <input
                        type="text"
                        name="Designation"
                        placeholder="Designation"
                        className="form-control mb-3"
                      />
                      <div className="sbmit_btn text-end">
                        <button type="submit">Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
