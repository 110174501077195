import QuestionImage from "../../../assets/images/question-mark.svg";

const FAQSection = () => {
  return (
    <div className="faq_sec pt-5">
      <div className="container py-5 position-relative">
        <div className="row">
          <div className="col-md-12">
            <h2 className="big_heading">FAQs</h2>
            <img src={QuestionImage} className="question_mark" alt="" />
            <div className="accordion mt-5" id="accordionExample">
              <div className="accordion-item">
                <h4 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    What are the features of YAAMS?
                  </button>
                </h4>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>The features of YAAMS were as follows:</p>
                    <ul>
                      <li>User-Centric design</li>
                      <li>Cloud-based platform</li>
                      <li>Seamless data reconciliation</li>
                      <li>Automated notifications</li>
                      <li>Attendance and leave tracking</li>
                      <li>Employee self service</li>
                      <li>Dynamic reports</li>
                      <li>Defined user roles</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h4 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    How much does YAAMS Cost?
                  </button>
                </h4>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    YAAMS pricing varies based on the number of employees at
                    your organization and features you select. To get a free
                    price quote simply mail us at{" "}
                    <a href="mailto:contact@stellardigital.in">
                      contact@stellardigital.in
                    </a>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h4 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    What does YAAMS do?
                  </button>
                </h4>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    YAAMS is HR software that compiles and arranges all the data
                    you collect throughout the course of an employee's lifecycle
                    and helps you use it to do great things. YAAMS HR gives you
                    the time and information to concentrate on your most
                    valuable asset—your people—whether you're onboarding,
                    planning compensation, or creating a culture.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h4 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Is YAAMS on demand software version specific?
                  </button>
                </h4>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    No, the YAAMS on demand software is not version specific.
                    Every time new features are introduced or launched, the
                    existing system is quickly updated to make them available to
                    all clients.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
