import React from "react";
import logo from "../../assets/images/logo.png";
import {  Link } from "react-router-dom";

import { BiWindowAlt, BiChevronDown } from "react-icons/bi";
import { Navbar } from "react-bootstrap";
import { Container } from "react-bootstrap";
export default function Header() {
  return (
    <>
      <Navbar className="shadow-sm" expand="lg">
        <Container className="position-relative">
          <Navbar.Brand href="/">
            <img src={logo} Alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                {" "}
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                {" "}
                <Link to="/about" className="nav-link">
                  About Us
                </Link>
              </li>
              <li class="nav-item dropdown megamenu">
                <Link
                  class="nav-link dropdown-toggle"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  Products <BiChevronDown />
                </Link>
                <ul class="dropdown-menu">
                  <li>
                    <Link to="/CoreHr">
                      <BiWindowAlt /> Core Hr
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <BiWindowAlt /> Time & Attendance
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <BiWindowAlt /> Payroll
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <BiWindowAlt /> Employee Onboarding
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <BiWindowAlt /> Employee Onboarding
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <BiWindowAlt /> Employee Onboarding
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <BiWindowAlt /> Employee Onboarding
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <BiWindowAlt /> Employee Onboarding
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link to="" className="nav-link">
                  Features
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/contactus" className="nav-link">
                  Contact Us
                </Link>
              </li>
            </ul>
            <Link to="#" class="try_free_btn">
              Try it free
            </Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
