import React, { useEffect } from "react";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

import AllDetails from "../contactus/AllDetails";
import LetsConnect from "../getstarted/LetsConnect";
export default function ContactUs() {
  
  useDocumentTitle("Contact Us")

  return (
    <>
      <LetsConnect />
      <AllDetails />
    </>
  );
}
