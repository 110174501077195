import React from "react";
import upgrade from "../../assets/images/upgrade.svg";
import {
  BsFillCalendarCheckFill,
  BsFillLightbulbFill,
  BsFillClockFill,
} from "react-icons/bs";
import { FaUser } from "react-icons/fa";
export default function UpgradeSection() {
  return (
    <>
      <div className="upgrade_sec">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6 offset-md-3 text-center">
              <h2 className="big_heading">
                Upgrade your management system a full 360
              </h2>
            </div>
          </div>
          <div className="row upgrade_circle mt-4">
            <div className="col-md-4">
              <div className="upgrade_box">
                <div className="icon_up">
                  <BsFillCalendarCheckFill />
                </div>
                <h5>Workforce Planning</h5>
                <p>
                  Become future-ready with hybrid staffing models, extensive
                  manpower budgeting, annual operating plans, compensation{" "}
                </p>
              </div>
              <div className="upgrade_box mt-5">
                <div className="icon_up">
                  <BsFillLightbulbFill />
                </div>
                <h5>Workforce Planning</h5>
                <p>
                  Become future-ready with hybrid staffing models, extensive
                  manpower budgeting, annual operating plans, compensation{" "}
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <img src={upgrade} className="img-fluid" alt="" />
            </div>
            <div className="col-md-4">
              <div className="upgrade_box text-end">
                <div className="icon_up ms-auto">
                  <BsFillClockFill />
                </div>
                <h5>Workforce Planning</h5>
                <p>
                  Become future-ready with hybrid staffing models, extensive
                  manpower budgeting, annual operating plans, compensation{" "}
                </p>
              </div>
              <div className="upgrade_box mt-5 text-end">
                <div className="icon_up ms-auto">
                  <FaUser />
                </div>
                <h5>Workforce Planning</h5>
                <p>
                  Become future-ready with hybrid staffing models, extensive
                  manpower budgeting, annual operating plans, compensation{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
