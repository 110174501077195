import React from "react";
import rightArrow from "../../assets/icons/right-arrow.svg";
import rightArrowUp from "../../assets/icons/right-arrow-up.svg";
export default function GetStarted() {
  return (
    <>
      <div className="get_strtd py-5">
        <div className="container">
          <div className="row gx-lg-5">
            <div className="col-md-12 mb-5">
              <h2
                className="big_heading text-center"
                style={{ color: "#DD3944" }}
              >
                Get started with YAAMS
              </h2>
              <p className="text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                nunc ante velit vitae.
                <br />
                Est tellus vitae, nullam lobortis enim.
              </p>
            </div>
            <div className="col-md-4 position-relative">
              <img src={rightArrow} className="arrow-top-down" alt="arrow" />
              <div className="get_started_box">
                <span>1</span>
                <h4>Choose Plans</h4>
                <p className="mb-0">
                  Choose the features you want to use free for 30 days. After
                  that you’ll be charged fee to continue.
                </p>
              </div>
            </div>
            <div className="col-md-4 position-relative">
              <div className="get_started_box">
                <span>2</span>
                <h4>Register in YAAMS</h4>
                <p class="mb-0">
                  After successful registration an email will be sent to the HR
                  with login credentials.
                </p>
              </div>
              <img src={rightArrowUp} className="arrow-bottom-up" alt="" />
            </div>
            <div className="col-md-4">
              <div className="get_started_box">
                <span>3</span>
                <h4>Enter Details</h4>
                <p className="mb-0">
                  After you sign in you and onboard multiple employees and start
                  using the features.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
