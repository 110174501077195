import Slider from "react-slick";

import homeScreen from "../../../../assets/images/home-screen.svg";
import services from "../../../../assets/images/services-screen.svg";
import scan from "../../../../assets/images/scan-screen.svg";

import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const ImageSlide = () => {
  
  const NextArrow = ({ onClick }) => {
    return (
      <div className="slick-arrow slick-next" onClick={onClick}>
        <AiOutlineRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="slick-arrow slick-prev" onClick={onClick}>
        <AiOutlineLeft />
      </div>
    );
  };

  // const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    // beforeChange: (current, next) => setImageIndex(next),
  };

  return (

    <div >
      <Slider {...settings}>
        <div>
          <img src={homeScreen} className="img-fluid" alt="" />
        </div>

        <div>
          <img src={services} className="img-fluid" alt="" />
        </div>

        <div>
          <img src={scan} className="img-fluid" alt="" />
        </div>

        <div>
          <img src={homeScreen} className="img-fluid" alt="" />
        </div>

        <div>
          <img src={services} className="img-fluid" alt="" />
        </div>

        <div>
          <img src={scan} className="img-fluid" alt="" />
        </div>
      </Slider>
    </div>
  );
};

export default ImageSlide;
