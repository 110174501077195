import React from "react";
import ImageSlide from "./imageslider/ImageSlide";

export default function CheckOut() {
  return (
    <>
      <div className="app_interface py-5">
        <div className="container">
          <div className="row gx-lg-5">
            <div className="col-md-12">
              <h2
                className="big_heading text-center"
                style={{ color: "#DD3944" }}
              >
                Checkout Our App Interface Look
              </h2>
              <p className="text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                nunc ante velit vitae.
                <br />
                Est tellus vitae, nullam lobortis enim.
              </p>
              <ImageSlide />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
