import React from "react";
import { MdOutlineAirplanemodeActive } from "react-icons/md";
import shield from "../../assets/icons/shield.svg";
import rocket from "../../assets/images/rocket.svg";
import {AiFillEye} from "react-icons/ai"
export default function VisionMisson() {
  return (
    <>
      <div className="vision_mission">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="vision_box mt-4">
                <div className="v_icon">
                  <MdOutlineAirplanemodeActive />
                </div>
                <h5>Our Mission</h5>
                <p className="mb-0">
                  We strive to provide companies with a feature-rich,
                  cost-effectiveattendance management solution that reduces
                  repetitive task while enhancing productivity.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="vision_box mt-4">
                <div className="v_icon">
                  <AiFillEye/>
                </div>
                <h5>Our Vision</h5>
                <p className="mb-0">
                  Et nulla hendrerit viverra consequat, morbi. Sit amet,
                  dignissim at tincidunt fermentum, nec, odio placerat integer.
                  Enim pulvinar sed viverra lectus vel consectetur odio lacus.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="secure">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6">
              <div className="secure">
                <img src={shield} className="img-fluid" alt="" />
                <h3>100%</h3>
              </div>
              <h4 className="mt-3">Secure and Reliable</h4>
              <p>
                We strive to provide companies with a feature-rich,
                cost-effectiveattendance management solution that reduces
                repetitive task while enhancing productivity.
              </p>
            </div>
            <div className="col-md-6">
              <div className="secure_box">
                <img src={rocket} alt="" />
                <h4>Secure accessibility</h4>
                <p>
                  Decide who get to see what. Restricted access based on job
                  roles, heirarchies, or locations. With granular access
                  controls, allow users to only see what’s relevant to them.
                </p>
              </div>
              <div className="secure_box">
                <img src={rocket} alt="" />
                <h4>Data protection</h4>
                <p>
                  Data integrity is one of our core functions. We encrypt your
                  data at rest, in transit, and during backup.
                </p>
              </div>
              <div className="secure_box">
                <img src={rocket} alt="" />
                <h4>Supreme privacy</h4>
                <p>
                  We’ll handle employee data and company assets without showing
                  ads or selling this data to third parties. Your privacy is
                  assured with YAAMS
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
