import React from "react";

import about from "../../assets/images/about.svg";
export default function AboutCompany() {
  return (
    <>
      <div className="inner_banner inner_background">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6">
              <div className="form_box">
                <h2 className="big_heading">About the Company</h2>
                <p>
                  Et nulla hendrerit viverra consequat, morbi. Sit amet,
                  dignissim at tincidunt fermentum, nec, odio placerat integer.
                  Enim pulvinar sed viverra lectus vel consectetur odio lacus.
                </p>
                <p>
                  Quis laoreet sit amet augue sed porttitor et lacus, volutpat.
                  Duis id risus pellentesque.
                </p>
                <p>
                  Sit consectetur sed in risus. Vehicula ipsum proin in sodales
                  lacus, maecenas. Sollicitudin amet ac luctus eu ridiculus odio
                  arcu gravida.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <img src={about} className="img-fluid" alt="About" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
