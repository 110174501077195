import React from "react";
import { BiEnvelope } from "react-icons/bi";
import { BsFillTelephoneFill } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { Link } from "react-router-dom";
export default function AllDetails() {
  return (
    <>
      <div className="all_details">
        <div className="container py-5">
          <div className="row py-4 all_details_row">
            <div className="col-md-4">
              <div className="detail_box text-center">
                <BiEnvelope size={"25px"} color="red" className="mb-2" />
                <h4>Mail</h4>
                <Link href="mailto:contact@yaams.net">contact@yaams.net</Link>
              </div>
            </div>
            <div className="col-md-4">
              <div className="detail_box text-center">
                <BsFillTelephoneFill
                  size={"25px"}
                  color="red"
                  className="mb-2"
                />
                <h4>Phone</h4>
                <Link href="tel:9311740931">+919311740931</Link>
              </div>
            </div>
            <div className="col-md-4">
              <div className="detail_box text-center">
                <GoLocation size={"25px"} color="red" className="mb-2" />
                <h4>Address</h4>
                <span>
                  Flaviant House 35 B Jacranda Marg DLF Phase 2, Gurgaon -
                  122002 Haryana
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12">
              <h2 className="big_heading">Reach Us</h2>
              <iframe
                title="Contact map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.78376584543!2d77.08947231455618!3d28.486057997447155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19544f5ccb8b%3A0xd9e46dd378a75737!2sFlaviant%20House!5e0!3m2!1sen!2sin!4v1665737389869!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>

              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.78376584543!2d77.08947231455618!3d28.486057997447155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19544f5ccb8b%3A0xd9e46dd378a75737!2sFlaviant%20House!5e0!3m2!1sen!2sin!4v1665737389869!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
