import React from 'react'
import { Link } from 'react-router-dom'

export default function Management() {
  return (
    <>
    <div className="management">
    <div className="container py-5">
        <div className="row">
            <div className="col-md-8 text-center offset-md-2">
                <h2 className="big_heading">Ready to revolutionize your Attendance management process?</h2>
                <div className="signup_btns mt-4"><Link href="#">Signup for free</Link> <Link href="#">Request a demo</Link></div>
            </div>
        </div>
    </div>
</div>
    </>
  )
}
