import React from "react";
import { Link } from "react-router-dom";
export default function Manage() {
  return (
    <>
      <div className="manage_org">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-8">
              <h2>Start Your Free Trial Today</h2>
              <h6>Find the perfect plan for you.</h6>
            </div>
            <div className="col-md-4">
              <Link className="free_trial">Signup For Free Trial</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
