import React from "react";
import { Link } from "react-router-dom";
import Rocket from "../../../assets/images/rocket.svg";
import Corehr from "../../../assets/images/core-hr.svg";
import { AiOutlineArrowRight } from "react-icons/ai";
export default function CoreHr() {
  return (
    <>
      
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-core"
            role="tabpanel"
            aria-labelledby="pills-core-tab"
            tabindex="0"
          >
            <div className="row">
              <div className="col-md-6">
                <img src={Corehr} alt="Core HR" className="img-fluid" />
              </div>
              <div className="col-md-6">
                <div className="feature_text">
                  <h3>Core HR</h3>
                  <p>
                    Now managing HR tasks doesn't require you to stress yourself
                    out with tedious spreadsheets or inflexible methods. Use
                    YAAMS, which has capabilities to relieve you of
                    administrative work.
                  </p>
                  <ul>
                    <li>
                      <img src={Rocket} alt="Rocket" />
                      Employee Profiles
                    </li>
                    <li>
                      <img src={Rocket} alt="Rocket" />
                      User Assignments & Permissions
                    </li>
                    <li>
                      <img src={Rocket} alt="Rocket" />
                      Role-Based Access
                    </li>
                  </ul>
                  <Link href="#" className="more">
                    Know more <AiOutlineArrowRight color="red" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      
    </>
  );
}
