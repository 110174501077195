import React from "react";

import MiddleSection from "../midsection/MiddleSection";
import GetStarted from "../getstarted/GetStarted";
import LetsConnect from "../getstarted/LetsConnect";
import UpperSection from "../homecomp/UpperSection";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import FAQSection from "../homecomp/lowersection/FAQSection";

export default function Home() {
  useDocumentTitle("Home")
  return (
    <>
      <UpperSection />
      <MiddleSection />
      <GetStarted />
      <LetsConnect />
      <FAQSection/>
    </>
  );
}
